<template>
    <div>
        <a-drawer title="详情" :width="720" :visible="show" :body-style="{ paddingBottom: '80px' }" @close="detailClose">
            <div class="drawer-content" style="padding: 16px">
                <div class="order-detail-title">{{detailInfo.name}}</div>
                <div class="order-detail-subtitle">分类：{{detailInfo.facilitiesCategoryName}}
                    <a-tag style="margin-left: 16px:float: right">{{detailInfo.status==1?'空置中':detailInfo.status==2?'使用中':'已停用'}}</a-tag>
                </div>
                <a-divider></a-divider>
                <a-descriptions layout="vertical" style="margin-left: 6px">
                    <a-descriptions-item label="开放时间">{{detailInfo.openStartDate}} - {{detailInfo.openEndDate}}</a-descriptions-item>
                    <a-descriptions-item label="设施位置">{{detailInfo.address}}</a-descriptions-item>
                    <a-descriptions-item label="创建时间">{{detailInfo.createDate}}</a-descriptions-item>
                    <a-descriptions-item label="备注" :span="2">{{detailInfo.remakes}}</a-descriptions-item>
                    <a-descriptions-item label="是否开启">{{detailInfo.status==2?'是':'否'}}</a-descriptions-item>
                    <a-descriptions-item label="上传文件" :span="3">
                        <div v-for="(item,index) in detailInfo.docList" :key="index">
                            <a :href="$ImgUrl(item.url)" target="tar">{{item.url}}</a>
                        </div>
                    </a-descriptions-item>
                </a-descriptions>
                <div class="order-detail-title" style="margin-top: 16px">设备启停记录</div>
			    <a-divider></a-divider>
                <a-table :columns="columns" :data-source="tableData" :pagination="pagination" @change="handlerChange">

                </a-table>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import {findFacilityInfo,findRecordList} from "@/api/operation/facility"
export default {
    props: {
		show: {
			type: Boolean,
			default: false,
		},
		detailId: Number,
	},
    data() {
        return {
            detailInfo: {},
            activeId: undefined,
            pagination: {
                current: 1,
                total: 0,
                pageSize: 10,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            columns: [
                {
                    title: "操作时间",
                    width: "25%",
                    dataIndex: "stopDate",
                },
                {
                    title: "执行操作",
                    width: "25%",
                    dataIndex: "type",
                    customRender: function(type) {
                        if(type == 1) {return '启用'}
                        else {return '停用'}
                    }
                },
                {
                    title: "操作原因",
                    width: "50%",
                    dataIndex: "stopReason",
                },
            ],
            tableData: [],
        }
    },
    methods: {
        detailClose() {
            this.$emit("detailClose");
        },
        handlerChange(val) {
            let obj = {
                facilitiesManageId: this.activeId,
                pageNum: val.current,
                size: val.pageSize
            };
            findRecordList(obj).then(res => {
                this.tableData = res.data.rows
                this.pagination.total = res.data.total
            })
        }
    },
    watch: {
		detailId: {
			handler(val) {
				if (val != null) {
                    this.activeId = val;
                    findFacilityInfo({facilitiesManageId: val}).then(res => {
                        let data = res.data;
                        this.detailInfo = data;
                    });
                    let obj = {
                        facilitiesManageId: val,
                        pageNum: this.pagination.current,
                        size:this.pagination.pageSize
                    }
                    findRecordList(obj).then(res => {
                        this.tableData = res.data.rows
                        this.pagination.total = res.data.total
                    })
				}
			},
			immediate: true,
		},
	},
}
</script>

<style lang="less">
.order-detail-title {
	padding: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
}
.order-detail-subtitle {
	padding: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.65);
}
</style>
<template>
    <div>
        <div class="cardTitle">
            <a-space size="large">
                <!-- <span>{{form.type == 1?'设施管理':'设备管理'}}</span> -->
                <a-radio-group
                v-model="form.type"
                button-style="solid"
                @change="typeChange"
                >
                <a-radio-button :value="1"> 设施管理 </a-radio-button>
                <a-radio-button :value="2"> 设备管理 </a-radio-button>
                </a-radio-group>
            </a-space>
        </div>
        <div class="search-box">
            <a-row>
                <a-col :span="20">
                <a-space size="large">
                    <a-input v-model="form.name" style="width: 200px" :placeholder="form.type == 1 ?'设施名称':'设备名称'"></a-input>
                    <a-select v-model="form.facilitiesCategoryId" style="width: 200px" :placeholder="form.type == 1 ?'设施分类':'设备分类'">
                        <a-select-option v-for="(item) in faList" :value="item.id" :key="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                    <a-input v-model="form.code" style="width: 200px" :placeholder="form.type == 1 ?'设施编号':'设备编号'"></a-input>
                    <a-select v-model="form.status" style="width: 200px" :placeholder="form.type == 1 ?'设施状态':'设备状态'">
                        <a-select-option :value="1">空置中</a-select-option>
                        <a-select-option :value="2">使用中</a-select-option>
                        <a-select-option :value="3">已停用</a-select-option>
                    </a-select>
                    <a-range-picker @change="handlerTime" v-model="selTime" style="width: 240px" value-format="YYYY-MM-DD HH:mm:ss" :placeholder="['添加开始时间','添加结束时间']"></a-range-picker>
                    <a-button type="primary" @click='getData'>查 询</a-button>
                    <a-button @click='reset'>重 置</a-button>
                </a-space>
                </a-col>
            </a-row>
        </div>
        <a-button style="margin: 10px" class="add-btn" @click="addShow = true;mode = 1">{{form.type == 1 ?'添加设施':'添加设备'}}</a-button>
        <div class="main">
            <a-table :columns="columns" :data-source="tableData" :pagination="pagination" @change="handlerChange" :scroll="{ x: 3200 }"
                :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: selectionChoosed,
                }"
                :row-key="
                (record, index) => {
                return record.id;
                }">
                    <template slot="quality" slot-scope="text,row">
                        {{row.warrantyPeriodYears}} 年 {{row.warrantyPeriodMonths}} 个月
                    </template>
                    <template slot="time" slot-scope="text,row">
                        {{row.validityStart}} 至 {{row.validityEnd}}
                    </template>
                    <template slot="document" slot-scope="text,row">
                        <div v-for="(item,index) in row.docList" :key="index">
                            <a :href="$ImgUrl(item.url)" target="tar">{{item.url}}</a>
                        </div>
                    </template>
                    <template slot="action" slot-scope="text,row">
                        <span><a @click=edit(row)>编辑</a></span>
                        <span><a style="margin-left: 8px" @click=detail(row)>详情</a></span>
                        <span><a style="margin-left: 8px;color:red" @click=del(row)>删除</a></span>
                    </template>
            </a-table>
            <div class="action">
                <a-dropdown :disabled="!hasSelected">
                    <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="del"> 批量删除 </a-menu-item>
                    </a-menu>
                    <a-button> 批量操作 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <span style="margin-left: 8px">
                    <template v-if="hasSelected">
                    {{ `已选择 ${selectedRowKeys.length} 条` }}
                    </template>
                </span>
            </div>
        </div>
        <a-drawer :title="form.type==1&&mode==1?'添加设施分类':form.type==2&&mode==1?'添加设备分类':form.type==1&&mode==2?'编辑设施分类':'编辑设备分类'"
             :width="720" :visible="addShow" :body-style="{ paddingBottom: '60px' }" @close="addClose">
            <div class="drawer-content">
                <a-row>
                    <a-form-model :model="addForm" :rules="rules">
                    <a-col :span="12">
                        <a-form-model-item :label="form.type == 1 ?'设施名称':'设备名称'" prop="name">
                            <a-input style="width: 300px" placeholder="请输入名称" v-model="addForm.name"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item :label="form.type == 1 ?'设施位置':'设备位置'" prop="address">
                            <a-input style="width: 300px" placeholder="请输入位置" v-model="addForm.address"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item :label="form.type == 1 ?'设施分类':'设备分类'" prop="facilitiesCategoryId">
                            <a-select style="width: 300px" placeholder="请选择分类" v-model="addForm.facilitiesCategoryId">
                                <a-select-option v-for="(item) in faList" :value="item.id" :key="item.id">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item :label="form.type == 1 ?'设施品牌':'设备品牌'" prop="brand">
                            <a-input style="width: 300px" placeholder="请输入品牌" v-model="addForm.brand"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="采购费用" prop="brand">
                            ¥ <a-input-number :min="0" :step="0.01" style="width: 150px;margin-left:8px" placeholder="请输入" v-model="addForm.procurementCosts"></a-input-number>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="采购厂家" prop="brand">
                            <a-input style="width: 300px" placeholder="请输入" v-model="addForm.purchasingManufacturer"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="厂家电话" prop="brand">
                            <a-input style="width: 300px" placeholder="请输入" v-model="addForm.manufacturerPhone"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="质保期限" prop="brand">
                            <a-input-number :min="0" style="width: 120px" placeholder="请输入" v-model="addForm.warrantyPeriodYears"></a-input-number> 年
                            <a-input-number :min="0" :max="12" style="width: 120px" placeholder="请输入" v-model="addForm.warrantyPeriodMonths"></a-input-number> 个月
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="有效期" prop="brand">
                            <a-range-picker @change="changeAT" v-model="actTime" style="width: 300px" value-format="YYYY-MM-DD HH:mm:ss" :placeholder="['有效期开始','有效期结束']"></a-range-picker>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="开放时间" prop="brand">
                            <a-range-picker @change="changeOT" v-model="openTime" :mode="['time','time']" show-time format="HH:mm:ss" value-format="HH:mm" style="width: 300px" :placeholder="['开放时间开始','开放时间结束']"></a-range-picker>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="22">
                        <a-form-model-item label="上传文件" prop="brand">
                            <a-upload
                                name="file"
                                :multiple="true"
                                :action="`${$upload}`"
                                :file-list="documentList"
                                accept=".doc,.DOC,.xls,.XLS,.xlsx,.XLSX,.pdf,.PDF"
                                :headers="uploadHeaders"
                                @change="changeFile"
                                :beforeUpload='beforeUpload'
                            >
                                <a-button> <a-icon type="upload" /> 上传附件</a-button>
                            </a-upload>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="22">
                        <a-form-model-item label="备注" prop="brand">
                            <a-textarea :autosize="{minRows:8}" v-model="addForm.remakes" placeholder="请输入备注，不超过1024字"></a-textarea>
                            <div>
                                已输入 {{addForm.remakes.length}} / 1024 字
                            </div>
                        </a-form-model-item>
                    </a-col>
                    </a-form-model>
                </a-row>
            </div>
            <div class="drawer-footer" style="float: left">
                <a-button @click="addConfirm" type="primary" :style="{ marginRight: '8px' }">确认</a-button>
                <a-button @click="addClose"> 关闭 </a-button>
            </div>
        </a-drawer>
        <detailForm :show="detailShow" @detailClose="detailClose" :detailId="detailId"></detailForm>
    </div>
</template>

<script>
import store from "@/store";
import detailForm from "./depend/detail.vue";
import {getFacilitiesList} from "@/api/operation/facility"
import {facilityInfoList,addFacilityInfo,updateFacilityInfo,findFacilityInfo,delFacilityInfo} from "@/api/operation/facility"
import { form ,addForm, rules, columns } from "./depend/config";
export default {
    components: {
		detailForm,
	},
    data() {
        return {
            form:form,
            columns: columns,
            rules: rules,
            faList: [],
            tableData: [],
            selTime: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 10,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            selectedRowKeys: [],
            //新增编辑
            addForm: addForm,
            actTime: [],
            openTime: [],
            addShow: false,
            mode: 1,
            activeId: undefined,
            fileList: [],
            //文件上传
            documentList: [],
            uploadHeaders: {
				"manage-login-token": store.getters.getToken,
			},
            //详情
            detailShow: false,
            detailId: undefined
        }
    },
    created() {
        this.getApi()
        this.getData()
    },
    methods: {
        getApi() {
            let obj = {
                pageNum: 1,size: 1000,type: this.form.type
            }
            getFacilitiesList(obj).then(res => {
                let data = res.data.rows;
                this.faList = data;
            })
        },
        getData() {
            let obj = Object.assign(this.form, {pageNum:this.pagination.current,size:this.pagination.pageSize})
            facilityInfoList(obj).then(res => {
                this.tableData = res.data.rows;
                this.pagination.total = res.data.total
            })
        },
        reset() {
            this.form.name = '';
            this.form.facilitiesCategoryId = undefined;
            this.form.code = '';
            this.form.status = undefined;
            this.form.createStartDate = '';
            this.form.createEndDate = '';
            this.getData()
        },
        handlerTime() {
            this.form.createStartDate = val[0];
            this.form.createEndDate = val[1];
        },
        changeAT(val) {
            this.addForm.validityStart = val[0];
            this.addForm.validityEnd = val[1];
        },
        changeOT(val) {
            this.addForm.openStartDate = val[0];
            this.addForm.openEndDate = val[1];
        },
        typeChange() {
            this.getApi()
            this.getData()
        },
        handlerChange(val) {
            this.pagination.current = val.current;
            this.pagination.pageSize = val.pageSize;
            this.getData()
        },
        //批量删除
        selectionChoosed(val) {
            this.selectedRowKeys = val
        },
        handleMenuClick(data) {
            if (data.key === "del") {
                this.$confirm({
				title: "是否删除选中的模版？",
				icon:'delete',
				onOk:async()=>{
                        let res = await delFacilityInfo({facilitiesManageIds: this.selectedRowKeys})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.selectedRowKeys = [];
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
                })
			}
        },
        del(val) {
            this.$confirm({
                title: "是否删除该信息？",
                icon:'delete',
                onOk:async()=>{
                    let res = await delFacilityInfo({facilitiesManageIds: [val.id]})
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                },
            })
        },
        //新增编辑
        edit(val) {
            this.activeId = val.id
            findFacilityInfo({facilitiesManageId: val.id}).then(res => {
                let data = res.data
                this.addForm = {
                    name: data.name,
                    address: data.address,
                    facilitiesCategoryId: data.facilitiesCategoryId,
                    brand: data.brand,
                    procurementCosts: data.procurementCosts,
                    purchasingManufacturer: data.purchasingManufacturer,
                    manufacturerPhone: data.manufacturerPhone,
                    warrantyPeriodYears: data.warrantyPeriodYears,
                    warrantyPeriodMonths: data.warrantyPeriodMonths,
                    validityStart: data.validityStart,
                    validityEnd: data.validityEnd,
                    openStartDate: data.openStartDate.substr(0,5),
                    openEndDate: data.openEndDate.substr(0,5),
                    remakes: data.remakes,
                    docUrls: [],
                };
                this.actTime = [data.validityStart,data.validityEnd];
                this.openTime = [data.openStartDate,data.openEndDate];
                if (res.data.docList.length > 0) {
					const file = [];
					for (let item of res.data.docList) {
						let obj = {
							name: item.url.split("_")[0] + "." + item.url.split(".")[1],
							url: this.$ImgUrl(item.url),
							uid: item.url.split("_")[1],
							status: "done",
							thumbUrl: this.$ImgUrl(item.url),
						};
						file.push(obj);
					}
					this.documentList = file;
				} else {
					this.addForm.docUrls = [];
                    this.documentList= [];
				};
                this.addShow = true;
            })
            this.mode = 2;
        },
        addConfirm() {
            // 附件
			let arr = [];
			for (let k of this.documentList) {
				if (k.response) {
					arr.push(k.response.data);
				} else {
					arr.push(k.url.split("/")[k.url.split("/").length - 1]);
				}
			}
			this.addForm.docUrls = arr;
            if(this.mode == 1) {
                let obj = Object.assign(this.addForm,{type: this.form.type})
                addFacilityInfo(obj).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.addClose()
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            } else if (this.mode == 2) {
                let obj = Object.assign(this.addForm,{id: this.activeId,type: this.form.type})
                updateFacilityInfo(obj).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.addClose()
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        addClose() {
            this.addForm = {
                name: '',
                address: '',
                facilitiesCategoryId: undefined,
                brand: '',
                procurementCosts: '',
                purchasingManufacturer: '',
                manufacturerPhone: '',
                warrantyPeriodYears: 0,
                warrantyPeriodMonths: 0,
                validityStart: '',
                validityEnd: '',
                openStartDate: '',
                openEndDate: '',
                remakes: '',
                docUrls: [],
            };
            this.openTime = [];
            this.actTime = [];
            this.documentList = [];
            this.addShow = false;
        },
        //详情
        detail(val) {
			this.detailId = val.id;
            this.detailShow = true;
        },
        detailClose() {
            this.detailShow = false;
			this.detailId = null;
        },
        //文件上传
        changeFile(info) {
			this.documentList = info.fileList;
			if (info.file.status !== "uploading") {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === "done") {
				this.$message.success(`${info.file.name} 上传成功`);
				// this.form.annexUrls.push(info.file.response.data);
			} else if (info.file.status === "error") {
				this.$message.error(`${info.file.name} 上传失败`);
			}
		},
        beforeUpload(f, l) {
			const isLt50M = f.size / 1024 / 1024 < 50;
			if (!isLt50M) {
				this.$message.error(f.name + "文件大小超出限制，请修改后重新上传");
				return false;
			} else {
				return true;
			}
		},
    },
    computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
}
</script>

<style lang="less">

</style>
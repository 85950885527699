export const form= {
	type: 1,
	name: '',
	facilitiesCategoryId: undefined,
	code: '',
	status: undefined,
	createStartDate: undefined,
	createEndDate: undefined,
}

export const addForm = {
	name: '',
	address: '',
	facilitiesCategoryId: undefined,
	brand: '',
	procurementCosts: '',
	purchasingManufacturer: '',
	manufacturerPhone: '',
	warrantyPeriodYears: 0,
	warrantyPeriodMonths: 0,
	validityStart: '',
	validityEnd: '',
	openStartDate: '',
	openEndDate: '',
	remakes: '',
    docUrls: [],
}

export const columns = [
	{
		title: "设施编号",
		width: "10%",
		dataIndex: "code",
	},
	{
		title: "设施分类名称",
		width: "8%",
		dataIndex: "facilitiesCategoryName",
	},
	{
		title: "设施名称",
		width: "9%",
		dataIndex: "name",
	},
    {
		title: "添加人",
		width: "8%",
		dataIndex: "createName",
	},
	{
		title: "设施状态",
		width: "8%",
		dataIndex: "status",
		customRender: function(status) {
			switch(status) {
				case 1: return '空置中'
				case 2: return '使用中'
				case 3: return '已停用'
			}
		}
	},
	{
		title: "设施品牌",
		width: "8%",
		dataIndex: "brand",
	},
	{
		title: "采购费用（元）",
		width: "8%",
		dataIndex: "procurementCosts",
	},
	{
		title: "采购厂家",
		width: "8%",
		dataIndex: "purchasingManufacturer",
	},
	{
		title: "厂家电话",
		width: "8%",
		dataIndex: "manufacturerPhone",
	},
	{
		title: "质保期限",
		width: "6%",
		dataIndex: "warrantyPeriodYears",
		scopedSlots: { customRender: "quality" },
	},
	{
		title: "设备有效期",
		width: "16%",
		dataIndex: "validityStart",
		scopedSlots: { customRender: "time" },
	},
	// {
	// 	title: "设备有效期开始",
	// 	width: "8%",
	// 	dataIndex: "validityStart",
	// },
	// {
	// 	title: "设备有效期结束",
	// 	width: "8%",
	// 	dataIndex: "validityEnd",
	// },
	{
		title: "文件",
		width: "12%",
		dataIndex: "docList",
		scopedSlots: { customRender: "document" },
	},
	{
		title: "备注",
		width: "8%",
		dataIndex: "remakes",
	},
	{
		title: "添加时间",
		width: "16%",
		dataIndex: "createDate",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]

export const rules = {
	name: [{ required: true, message: "请输入名称", trigger: "blur" }],
	content: [{ required: true, message: "请输入内容", trigger: "blur" }],
}

export function doStr(str, number) {
	if(str.length >= number) {
		return str.substr(0,number-1) + '...' 
	} else {
		return str
	}
}